.resume-wrapper {
  border: 1px solid grey;
  container-type: inline-size;
  width: 355px;
}

.resume-head h1 {
  font-size: 6cqw;
}

.resume-head h2 {
  font-size: 2cqw;
}

.resume-head h3 {
  font-size: 2cqw;
}
