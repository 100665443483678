.coverLetter-1-wrapper {
  max-width: 1200px;
  /* Set maximum width of the container */
  margin: 0 auto;
  /* Center the container horizontally */
  padding: 20px;

  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* Add some padding inside the container */
  font-size: 0.7vw !important;
}

.coverLetter-1-wrapper p {
  margin-bottom: 4px;
}

.coverLetter-1-head {
  text-align: center;
}

.coverLetter-1-head h1 {
  font-size: 1.3vw !important;
}

.coverLetter-1-head h3 {
  font-size: 1.1vw !important;
}

.coverLetter-1-head h4 {
  font-size: 1.1vw !important;
}