.Resum2_wrapper {
  border: 1px solid lightgray;
  min-height: 40vh;

  font-size: 0.5rem;
}

.Resume2_left {
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-height: 40vh;

}

.Resumer2_left_head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Resumer2_left_head span {
  height: 30px;
  width: 30px;
  align-items: start;
  border-radius: 50%;
  background: rgb(0, 85, 128);
}

.Resumer2_left_head h3 {
  font-size: 1rem;
}


.Resume2_left_feature {
  font-weight: 700;
  font-size: 0.45rem;
}

.Resume2_left_feature_summary {
  font-size: 0.50rem;
  margin-top: 10px;
}

.Resume2_left_feature_summary p span {
  font-weight: 700;
}

.Resume2_left_education {
  display: flex;
  flex-direction: column;
}

.Resume2_left_education h6 {
  font-size: 0.6rem;
  margin-bottom: 5px;
}

.Resume2_left_education p {
  font-size: 0.5rem;
}

.Resume2_left_education h5 {
  font-size: 0.6rem;
}

/* //right */

.Resume2_right {
  background: #3d3e42;
  width: 60%;
  padding: 10px;
  color: white;
  display: flex;
  font-size: 0.7rem;
  flex-direction: column;
}

.Resume2_right h5 {
  color: aquamarine;
  font-size: 0.6rem;
}

.Resume2_right_experience {
  width: 40%;
}

.Resume2_right_experience h6 {
  font-size: 0.5rem;
}

.Resume2_right_experience p {
  font-size: 0.4rem;
}

.Resume_skill_circle span {
  background-color: aquamarine;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}