.nav-head {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0px;
}

.nav-para {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin: 0px;
}

.nav-level {
  display: flex;
  align-items: center;
  /* background-color: #521986; */
  border-radius: 6px;
  padding: 8px 12px 9px 12px;
  margin: 0px 8px;
}

.nav-level h6 {
  margin: 0px;
}

.nav-notification {
  margin: 0px 8px;
}

.user-img-cover {
  margin-left: 8px;
  width: 60px;
  height: 60px;
}

.user-img {
  border-radius: 50%;
  border: 2px solid #f9ecff;
}

.nav-sec-1 {
  width: 73%;
}

.nav-sec-2 {
  width: 27%;
}

.profile-header {
  background-color: #ffa303;
  color: white;
  text-decoration: none;
}

.nav-sec-1 {
  display: none;
}

@media (max-width: 768px) {
  .nav-sec-1 {
    display: inline-block;
  }

  .navbar-logout-popup {
    position: absolute;
    top: 100%;
    right: -10%;
  }
}