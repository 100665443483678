* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h5 {
  margin-bottom: 0.5rem;
}

.resume_container {
  min-height: 40vh;
  margin-bottom: 20px;
}

.resume_head,
.resume_experience_wrapper,
.resume_experience_head,
.resume_experience_position {
  display: flex;
  flex-direction: column;
}

.resume_head h5,
.resume_experience_wrapper h5 {
  font-size: 1vw;
}

.resume_head p,
.resume_hobby span {
  font-size: 0.8vw;
  margin: 0;
}

.resume_head_position,
.resume_experience_head p,
.resume_experience_position p {
  margin-top: 5px;
  font-size: 0.5vw;
}

/* //experi3ence */
.resume_experience {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resume_experience_head h6,
.resume_experience_position h6,
.resume_skill h6 {
  font-size: 0.6vw;
}

.resume_skill div span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: aqua;
}
