.template-title {
  text-align: center;
  width: 93%;
}

.template-title h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #6750a4;
  margin-bottom: 20px;
}

.template-title h4 span {
  color: #4421a4;
}

.template-color-picker {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 93%;
  padding-right: 15px;
}

.template-color-picker div:first-child {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  place-content: center;
  color: #521986;
  display: flex;

  margin-left: auto;
}

.template-color-picker div:last-child {
  margin-left: auto;
  margin-right: 4em;
}

.template-color-picker div h6 {
  margin-top: 3px;
  font-weight: 700;
}

.template-color-picker div span {
  width: 30px;
  height: 30px;
  background: #facd48;
  border-radius: 6px;
  margin: 0 10px;
  border: 0.5px solid #303030;
}

.template-color-picker button {
  width: 11.5em;
  padding: 5px;
  border-radius: 6px;
  background: #521986;
  border-bottom: 0.5px solid #521986;
  color: #fff;
  border: none;
  outline: none;
  display: flex;
  line-height: 30px;
  text-align: center;
  align-items: center;
  place-content: center;
  font-weight: 600;
}

.rank-container {
  border: 0.5px solid #1313132c;
  padding: 20px 10px;
  text-align: center;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
}

.dashboard-template-heading {
  font-size: 12px;
  font-weight: 700;
}
