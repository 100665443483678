.contactInfo-changePassword-container,
.profile-subscription-container {
  display: flex;
  justify-content: space-between;
}

.contactInfo-container,
.changePassword-container,
.subscription-container,
.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f6eeff;
  border-radius: 10px;
  padding: 20px;
  width: 49%;
  margin-bottom: 40px;
}

.subscription-container {
  display: block;
}

.contactInfo-container h3,
.changePassword-container h3,
.subscription-container h3,
.profile-container h3 {
  color: #6750a4;
  font-size: 25px;
}

.contactInfo-container p,
.changePassword-container p,
.subscription-container p,
.profile-container p {
  font-weight: 600;
  font-size: 14px;
}

.contactInfo-container input,
.changePassword-container input,
.subscription-container input,
.profile-container input {
  display: block;
  width: 100%;
  border: 0.5px solid #6750a4;
  margin: 8px 0;
  border-radius: 10px;
  padding: 17px 15px;
  outline: none;
  font-size: 14px;
  color: #6750a4;
}

.contactInfo-container input::placeholder,
.subscription-container input::placeholder,
.profile-container input::placeholder,
.changePassword-container input::placeholder {
  font-size: 14px;
  color: #6650a474;
  font-weight: 600;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.contactInfo-container button,
.changePassword-container button,
.subscription-button-container button {
  background-color: #521986;
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
}

.subscription-button-container {
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
}

.profile-name-container {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.profile-name-container input {
  margin-bottom: 8px;
  width: 49%;
}

.profile-imageInfo-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.profile-imageInfo-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.profile-imageInfo-button-container button {
  background-color: transparent;
  border: 1px solid #6750a4;
  color: #6750a4;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 12px;
}

.profile-imageInfo-image-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.113);
  overflow: hidden;
}

.profile-imageInfo-image-container img {
  width: 100%;
  height: 100%;
}

.password-eye-2 {
  position: absolute;
  right: 15px;
}



@media (max-width: 768px) {
  .changePassword-container {
    width: 100%;
  }
}