/* .sidebar-container {
  position: absolute;
  z-index: 1;
  width: 70% !important;
} */

.sidebar-wrapper {
  background: #602a94;
  height: 88vh;
  position: absolute;
  transition: 1s;
}

.sidebar-head {
  height: 12vh;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidepanel-expand-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 1px solid white;
  width: 90%;
  background-color: white;
  color: #602a94;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.sidebar {
  height: 100vh;
  transition: 1s;

}

.sidebar__heading {
  position: relative;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  margin: 0px 0;
  padding-top: 8px;
  letter-spacing: 0.4px;
  top: -8px;
  left: 4px;
}



.sidebar__link {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  width: 100%;
  margin: 5px 0;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.sidebar__link:hover {
  color: inherit;
  text-decoration: none;
}

.wrapper__sidebar svg {
  font-size: 30px;
  margin-right: 1px;
}

.sidebar__link .wrapper__sidebar:hover {
  color: rgb(199, 196, 196);
}

.sidebar__link.active .wrapper__sidebar {
  color: #602a94;
  background-color: #fff;
  border-radius: 10px;
}

.sidebar__link.active .wrapper__sidebar svg {
  color: #602a94;
}

/* dashboard.css */
.resume-selector {
  display: flex;
  justify-content: space-around;
  border: 1px solid #ffa303;
  border-radius: 36px;
  text-align: center;
  color: #602a94;
  width: 80%;
  overflow: hidden;
}

.resume-selector div {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #ffa303;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.resume-selector div .icon {
  margin-right: 8px;
  margin-bottom: 3px;
}

.resume-selector .active {
  background-color: #602a94;
  border: 1px solid #602a94;
  color: #fff;
  -webkit-animation: slide-right 0.6s forwards;
  animation: slide-right 0.6s forwards;
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.create-resume {
  display: flex;
  justify-content: flex-start;
  margin: 25px 0;
  margin-bottom: 5px;
  width: 93%;
}

.create-resume img {
  margin-right: 5px;
  margin-bottom: 2px;
}

.create-resume div {
  background-color: #f7f3ff;
  width: 230px;
  color: #602a94;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  margin: 20px 10px;
}

.create-resume div .icon {
  margin-right: 5px;
  margin-bottom: 2px;
}

.create-resume .active {
  background-color: #602a94;
  color: #ffffff;
}

.select-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-right: 10px;
}

/* .resume-subtype-box {
  margin: "0";
  margin-right: "25px";
  font-size: "13px";
  font-weight: "700";
  padding: "20px 15px";
  text-align: "left";
  cursor: "pointer",

} */

@media (max-width: 768px) {
  .resume-selector {
    width: 100%;
  }

  .resume-subtype-box {
    width: 100%;
  }

  .create-resume {
    overflow: auto;
  }

  .create-resume-optionsBar {
    width: 100%;
  }

}