.card-main {
  width: 210px;
  height: 320px;
  border: 0.25px solid rgba(0, 0, 0, 0.187);
  border-radius: 10px;
  overflow: hidden;
  margin: 20px 0px;
  height: 370px;
  margin-right: 15px;
  margin-bottom: 0;
  transition: 0.3s all;
}

.card-main:hover {
  cursor: pointer;
  transform: scale(1.06);

}

.card-main div:nth-child(1) {
  height: 85%;
  background-color: #d5d5d5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #521986;
  overflow: hidden;
}

.card-main div:nth-child(1) img {
  width: 100%;
  height: 100%;
}

.card-main div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 0.25px solid rgba(0, 0, 0, 0.187);
  height: 15%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
}

.card-main div span {
  padding: 4px 10px;
  text-align: center;
  width: 70px;
  background: #ffb960;
  border-radius: 36px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}