.plan-box {
  padding: 40px 20px !important;
  background-color: #a383c1;
  margin-bottom: 10px !important;
  color: white;
  width: 150px;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
}

.plan-box-active {
  background-color: #602a94 !important;
}

.plan-font-active {
  font-size: 1.1rem;
  font-weight: 700;
}

.pay-btn button {
  background-color: #602a94;
  padding: 10px 25px;
  border: none;
  border-radius: 10px;
  color: white;
}

.subscription2-box {
  background-color: #602a94;
  color: white;
}

.plan-box-cmn {
  background-color: white;
  border-radius: 5px;
  display: flex;
  width: 22%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.plan-box-cmn button {
  background-color: #ffa500;
  color: black;
  width: 90%;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  font-weight: 700;
}

.plan-box-cmn h3 {
  font-weight: 700;
}

.plan-box-cmn h4 {
  font-weight: 700;
}

.plan-box-money span {
  font-size: 3.5rem;
  font-weight: 800;
}

.plan-box-money {
  display: flex;
  align-items: center;
}

.plan-box-money sub {
  font-size: 1.3rem;
  font-weight: 600;
  margin-right: 5px;
}

.plan-box-resume-cover span {
  font-weight: 600;
}

@media (max-width: 768px) {
  .plan-box-cmn {
    width: 100%;
  }
}