.form-main-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.form-main-2 .form-main {
  display: flex;
  justify-content: center;
}

.form-main-2 button {
  background-color: #fff;
  color: #521986;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px;
}

.form-main-2 button:hover {
  background-color: #521986;
  color: #ffffff;
  transition: 0.5s all ease-in-out;
}

.form-main-2 .active {
  background-color: #521986;
  color: #ffffff;
  transition: 0.5s all ease-in-out;
}

.years-circle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b69df8;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.years-circle span {
  padding: 1rem 1.2rem;
  background: #ffffff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.years-circle span:hover {
  background: #521986;
  color: #ffffff;
  cursor: pointer;
}

.years-circle span:nth-child(1) {
  border-radius: 360px;
  white-space: nowrap;
}

.collapseInput-main {
  background: #f6eeff;
  border: 0.5px solid #ffa303;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  overflow: hidden;
  /* hide textarea when collapsed */
  transition: height 0.3s ease-in-out, margin 0.3s ease-in-out;
  /* animate height and margin change */
}

.collapseInput-main .firstComp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapseInput-main .firstComp h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #22005d;
}

.collapseInput-main textarea {
  margin: 20px 0;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  padding: 10px;
  border: 1px solid #fff;
  transition: height 0.3s ease-in-out, margin 0.3s ease-in-out;
  /* animate height and margin change */
}

.collapseInput-main.open {
  height: auto;
  /* show all content when active */
  margin: 20px 0;
  /* add margin when active */
  border: 1px solid #ffa303;
}

.collapseInput-main.open textarea {
  height: auto;
  /* auto-resize textarea when active */
  margin: 20px 0;
  /* add margin when active */
  border: 1px solid #ffa303;
}

.collapseInput-main span {
  cursor: pointer;
}

.collapseInput-main textarea:focus {
  outline: 1px solid #ffa303;
}

.collapseInput-main .rotate {
  transform: rotate(-180deg);
}

.coverletter-experience-addmore-button {
  border: none;
  padding: 5px 8px;
  color: #521986;
  font-weight: 600;
  font-size: 14px;
  background-color: transparent;
}

.expGapDropDown button {
  background-color: #521986;
  color: white;
  width: 100%;
  margin: 5px 10px;
  padding: 15px;
}

.expGapDropDown button:hover {
  background-color: white;
  color: #521986;
}

.previw-card-wrapper {
  position: relative;
  border: 8px solid #b69df8;
  background-color: #b69df8;
  display: inline-block;
  padding: 2px;
  border-radius: 15px;
}

.change-template {
  display: flex;
  align-items: center;
  border-radius: 0 0 10px 10px;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 70px;
  padding: 1rem;
  border-radius: 20px;
}

.change-template h4 {
  font-size: 1.1rem;
  color: #521986;
}

.change-template button {
  background: #521986;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  line-height: 30px;
}

.responsive-preview-btn {
  background-color: #521986;
  color: white;
  border-radius: 5px;
}

@media (max-width: 568px) {
  .preview-wrapper {
    display: none;
  }
}
