.ai-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #521986; */
  border-radius: 20px;
  padding: 8px 10px;
  cursor: pointer;
  width: 120px;
  transition: 0s;
  height: 40px;
  position: relative;
}

.ai-button-wrapper-active {
  background-color: #521986;
}

.ai-button-wrapper-disabled {
  background-color: grey;
}

.ai-button-wrapper span {
  color: #521986;
  font-family: "Inter";
  display: flex;
  padding: 2px;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #ffffff;
}

.ai-button-wrapper label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: white;
}

.expand button {
  font-size: 0.7rem;
  background-color: white;
  color: #521986;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 12px;
  margin: 0 5px;
}

.ai-btn-tooltip {
  position: absolute;
  top: -150%;
  width: 150px;
  padding: 5px;
  left: -15%;
  background-color: #521986;
}

.ai-btn-tooltip h6 {
  color: white;
}
