.modal-resumeType-container {
    width: 700px;
    margin: 170px auto;
    padding: 15px;
}

.modal-resumeType-container h3 {
    text-align: center;
    color: white;
}

.modal-resumeType-container p {
    text-align: center;
    color: white;
}


.modal-resumeType-container input {
    display: block;
    width: 100%;
    border: 0.5px solid #6750a4;
    margin: 8px 0;
    border-radius: 10px;
    padding: 17px 15px;
    outline: none;
    font-size: 14px;
    color: #6750a4;
}

.resumeType-button-container,
.resumeType-fields-container {

    display: flex;
    justify-content: space-between;
    background-color: #f6eeff;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    padding: 10px 15px;
    margin: 15px 0;

}

.resumeType-fields-container {
    padding: 0;
}

.resumeType-button-container button {

    border-radius: 10px;
    padding: 20px 10px;
    margin: 10px 0;
    border: none;
    font-weight: 600;
    background-color: white;
    color: #521986;
    transition: all 0.2s;
}

.resumeType-button-container button:hover {


    background-color: #521986;
    color: white;
}


.resumeType-controlButton-container {
    display: flex;
    justify-content: space-between;

}

.resumeType-controlButton-container button {

    background-color: #521986;
    color: white;
    padding: 10px 20px;
    font-size: 20px;
    border: none;
    border-radius: 8px;

}

.changeTemplate-button {
    position: relative;
}

.change-template-dropdown-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-start;
    width: 100%;
    top: 40px;
    left: 0;
    border: 0.2px solid rgba(0, 0, 0, 0.153);
}

.change-template-dropdown-container p {
    font-size: 14px;
    background-color: white;
    color: #521986;
    margin: 0;
    font-family: 600;
    padding-left: 10px;
    text-align: left;
    padding: 3px 10px;
}

.change-template-dropdown-container p:hover {
    color: white;
    background-color: #ffa303;
}

.resume-card-template {
    width: 325px;
    position: relative;
    height: 630px;
}
.resume-card-template:hover {
    box-shadow: 2px 2px 15px 1px;
  }
