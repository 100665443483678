.videoDescription_wrapper {}

.videoDescription_wrapper h2 {
  font-weight: 700;
  font-size: 28px;
}

.videoDescription_wrapper p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(34, 0, 93, 1);
}

.videoDescription_Instruction {
  border: 3px solid rgba(82, 25, 134, 1);
  border-radius: 10px;
  padding: 1.5rem;
}

.videoDescription_Instruction h3 {
  font-size: 28px;
}

.videoDescription_Instruction ul li {
  font-size: 16px;
  font-weight: 500;
}

.videoDescription_Instruction p {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.videoDescription_Instruction_footer span {
  font-size: 16px;
  font-weight: 600;

}

.videoDescription_Instruction_footer span a {
  color: rgba(82, 25, 134, 1);
}

.videoDescription_Instruction_checkbox {
  height: 18px;
  width: 18px;
  accent-color: rgba(82, 25, 134, 1);
  border: 4px solid rgba(82, 25, 134, 1);
  border-radius: 5px;
  margin-bottom: 0;
}



/* recorder */
.VideoResume_recorder_wrapper button {
  background-color: rgba(82, 25, 134, 1);
  border-radius: 7px;
  color: white;
  padding: 0.5rem;
  font-size: 16px;
}

.VideoResume_targetjob h2 {
  font-weight: 700;
  font-size: 28px;
}

.VideoResume_targetjob p {
  font-weight: 500;
  font-size: 16px;
  color: rgba(82, 25, 134, 1);
}

.VideoResume_recorder {
  border: 3px solid orange;
  height: 250px;
  border-radius: 5px;
}

/* /editor */
.videoResume_editor {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  border: 2px solid orange;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  background-color: lightgray;
}

.videoResume_trimmer {
  display: flex;

}

.videoResume_trimmer button {
  height: 80px;
  width: 50px;
  background-color: rgba(82, 25, 134, 1);
  color: white;
  font-size: 40px;
}

.videoResume_trimmer_btn1 {
  border-radius: 15px 0 0 15px;
}

.videoResume_trimmer_btn2 {
  border-radius: 0 15px 15px 0;
}


/* trimmer */
/* .noUi-touch-area {
  background-color: rgba(82, 25, 134, 1) !important;
  color: white !important;
  height: 80px !important;
  width: 30px !important;
  display: flex;
  align-items: center;
} */

.videoResume-final {
  border-right: 1px solid lightgray;
}

.video-resume-final-left-card-btn {
  color: white;
  background: rgba(82, 25, 134, 1);

}