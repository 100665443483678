@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");

ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_9-3 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

ul.lst-kix_list_9-4 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li:before {
  content: " ";
}

.lst-kix_list_3-2 > li:before {
  content: " ";
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ul.lst-kix_list_9-1 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ul.lst-kix_list_9-2 {
  list-style-type: none;
}

.lst-kix_list_8-1 > li:before {
  content: " ";
}

ul.lst-kix_list_9-7 {
  list-style-type: none;
}

ul.lst-kix_list_9-8 {
  list-style-type: none;
}

.lst-kix_list_8-2 > li:before {
  content: " ";
}

ul.lst-kix_list_9-5 {
  list-style-type: none;
}

ul.lst-kix_list_9-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

.lst-kix_list_3-5 > li:before {
  content: " ";
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

.lst-kix_list_3-4 > li:before {
  content: " ";
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li:before {
  content: " ";
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ul.lst-kix_list_9-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.lst-kix_list_8-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

.lst-kix_list_8-7 > li:before {
  content: " ";
}

.lst-kix_list_3-8 > li:before {
  content: " ";
}

.lst-kix_list_8-5 > li:before {
  content: " ";
}

.lst-kix_list_8-6 > li:before {
  content: " ";
}

.lst-kix_list_8-3 > li:before {
  content: " ";
}

.lst-kix_list_3-6 > li:before {
  content: " ";
}

.lst-kix_list_3-7 > li:before {
  content: " ";
}

.lst-kix_list_8-4 > li:before {
  content: " ";
}

.lst-kix_list_8-8 > li:before {
  content: " ";
}

.lst-kix_list_5-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-8 > li:before {
  content: " ";
}

.lst-kix_list_5-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-7 > li:before {
  content: " ";
}

.lst-kix_list_5-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-1 > li:before {
  content: "o  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_5-7 > li:before {
  content: "o  ";
}

ul.lst-kix_list_8-4 {
  list-style-type: none;
}

ul.lst-kix_list_8-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_5-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-8 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_8-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_8-3 {
  list-style-type: none;
}

ul.lst-kix_list_8-8 {
  list-style-type: none;
}

ul.lst-kix_list_8-6 {
  list-style-type: none;
}

ul.lst-kix_list_8-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.lst-kix_list_5-4 > li:before {
  content: "o  ";
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_5-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_8-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_8-1 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_6-1 > li:before {
  content: " ";
}

.lst-kix_list_6-3 > li:before {
  content: " ";
}

.lst-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-4 > li:before {
  content: " ";
}

.lst-kix_list_6-2 > li:before {
  content: " ";
}

.lst-kix_list_6-8 > li:before {
  content: " ";
}

.lst-kix_list_6-5 > li:before {
  content: " ";
}

.lst-kix_list_6-7 > li:before {
  content: " ";
}

.lst-kix_list_7-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-6 > li:before {
  content: " ";
}

.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-7 > li:before {
  content: "o  ";
}

.lst-kix_list_7-4 > li:before {
  content: " ";
}

.lst-kix_list_7-6 > li:before {
  content: " ";
}

.lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-1 > li:before {
  content: " ";
}

.lst-kix_list_7-5 > li:before {
  content: " ";
}

.lst-kix_list_7-2 > li:before {
  content: " ";
}

.lst-kix_list_7-3 > li:before {
  content: " ";
}

ul.lst-kix_list_7-5 {
  list-style-type: none;
}

ul.lst-kix_list_7-6 {
  list-style-type: none;
}

ul.lst-kix_list_7-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_7-4 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

ul.lst-kix_list_7-7 {
  list-style-type: none;
}

ul.lst-kix_list_7-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_7-8 > li:before {
  content: " ";
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ul.lst-kix_list_7-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_7-2 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_7-7 > li:before {
  content: " ";
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-1 > li:before {
  content: " ";
}

.lst-kix_list_4-4 > li:before {
  content: " ";
}

.lst-kix_list_9-2 > li:before {
  content: " ";
}

.lst-kix_list_4-3 > li:before {
  content: " ";
}

.lst-kix_list_4-5 > li:before {
  content: " ";
}

.lst-kix_list_4-2 > li:before {
  content: " ";
}

.lst-kix_list_4-6 > li:before {
  content: " ";
}

.lst-kix_list_9-3 > li:before {
  content: " ";
}

.lst-kix_list_9-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_9-1 > li:before {
  content: " ";
}

.lst-kix_list_9-7 > li:before {
  content: " ";
}

.lst-kix_list_9-6 > li:before {
  content: " ";
}

.lst-kix_list_9-4 > li:before {
  content: " ";
}

.lst-kix_list_9-5 > li:before {
  content: " ";
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_1-0 > li:before {
  content: "\0000ef  ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

.lst-kix_list_9-8 > li:before {
  content: " ";
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_1-2 > li:before {
  content: "\0000ef  ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_1-4 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_1-7 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_1-5 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_1-6 > li:before {
  content: "\0000ef  ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.lst-kix_list_1-8 > li:before {
  content: "\0000ef  ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c0 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 53.1pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c3 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 147.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c16 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 42.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c15 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 189.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c7 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 306.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c27 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 147.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c25 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 0pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 189.9pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c13 {
  padding-top: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c41 {
  color: #0f3344;
  font-weight: 400;
  text-decoration: none;
  font-size: 1rem;
  font-family: "Calibri";
  font-style: normal;
}

.c42 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Calibri";
  font-style: normal;
}

.c11 {
  color: #804f92;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Calibri";
  font-style: normal;
}

.c28 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c9 {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c6 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 4pt;
  font-family: "Calibri";
  font-style: normal;
}

.c12 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c2 {
  padding-top: 0.2pt;
  padding-bottom: 11pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 11pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c8 {
  padding-top: 0pt;
  padding-bottom: 11pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c26 {
  padding-top: 12pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c34 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c40 {
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c31 {
  color: #ffffff;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-style: normal;
}

.c10 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c30 {
  margin-left: auto;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 11pt;
  line-height: 1;
  text-align: left;
}

.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c32 {
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
}

.c20 {
  background-color: #ffffff;
  max-width: 555.3pt;
  padding: 42.5pt 28.4pt 56.7pt 28.4pt;
}

.c38 {
  margin-left: 18pt;
  padding-left: 0pt;
}

.c23 {
  font-weight: 700;
  font-family: "Calibri";
}

.c29 {
  color: #000000;
  font-weight: 700;
}

.c39 {
  padding: 0;
  margin: 0;
}

.c22 {
  height: 27.1pt;
}

.c21 {
  height: 31.2pt;
}

.c33 {
  height: 9.2pt;
}

.c35 {
  padding: 0pt 5.8pt 0pt 5.8pt;
}

.c37 {
  height: 49.6pt;
}

.c18 {
  height: 11pt;
}

.c4 {
  height: 31.6pt;
}

.c36 {
  height: 122.4pt;
}

.c17 {
  height: 2.9pt;
}

.c14 {
  height: 36.9pt;
}

.title {
  color: #0f3344;
  font-size: 26pt;
  font-family: "Calibri";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}

.coverletterTemplate-1 p {
  font-size: 0.5rem;
  margin-bottom: 5px;
}
