.resume-temp-1-wrapper {
  width: 300px;
  /* width: 100%; */
}

.resume-temp-1-wrapper p {
  font-size: 0.45vw !important;
  color: black;
  margin-bottom: 3px !important;
  line-height: 8px !important;
}

.resume-temp-1-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resume-temp-1-head h1 {
  font-size: 8vw;
  font-weight: 600;
}

hr {
  z-index: -1;
}

.partition-hr {
  min-height: 100%;
  border: 0.5px solid lightgray;
}

.resume-temp-1-summary p {
  font-size: 1.1rem;
}

.resume-temp-1-content h3 {
  font-weight: 700;
}

.resume-temp-1-content p {
  margin-bottom: 8px;
}

.resume-temp-1-content h5 {
  color: grey;
}

.resume-temp-1-content ul {
  margin: 15px 0;
}

.resume-temp-1-content ul li {
  font-size: 0.7vw !important;
}

.resume-temp-1-head h1 {
  font-size: 1.2vw !important;
  margin-bottom: 2px;
}

.resume-temp-1-head h2 {
  font-size: 0.8vw !important;
}

.resume-temp-1-email {
  font-size: 0.6vw !important;
  margin: 5px 0;
}

.resume-temp-1-summary img {
  width: 3vw;
}

.resume-temp-1-summary p {
  font-size: 0.4vw !important;
}

.resume-temp-1-wrapper hr {
  margin: 3px 0;
}

.resume-temp-1-wrapper {
  font-size: 4px;
}

.resume-temp-1-content p {
  margin-bottom: 3px;
}

.resume-temp-1-content h3 {
  font-weight: 700;
  font-size: 0.6vw !important;
  margin: 3px 0;
}

aside h6 {
  font-size: 0.5vw !important;
}

.resume-temp-1-content h6 {
  font-size: 0.6vw !important;
}

.resume-temp-1-content ul li {
  font-size: 0.3vw !important;
}

.resume-temp-1-content h5 {
  font-size: 0.55vw !important;
  margin-bottom: 3px;
}

.resume-temp-1-content ul {
  margin: 0;
}

.resume-temp-1-content h6 {
  font-size: 0.5vw !important;
  margin: 2px 0;
}

.resume-temp-1-wrapper h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
